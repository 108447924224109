import React, { useState, useEffect, useRef } from 'react';
import '../styles/Services.css';
import ContactForm from './Form';
import Benefits from './Benefits';
import Benefits2 from './Benefits2';

const Services = () => {
    const [isVisible, setIsVisible] = useState({ nail: true, brow: false });

    const cursoBtnNailRef = useRef(null);
    const cursoBtnBrowRef = useRef(null);
    const cursoNailTextRef = useRef(null);
    const cursoBrowTextRef = useRef(null);

    const handleCursoNailClick = () => {
        setIsVisible(prevState => ({ nail: !prevState.nail, brow: false }));
    };

    const handleCursoBrowClick = () => {
        setIsVisible(prevState => ({ nail: false, brow: !prevState.brow }));
    };

    useEffect(() => {
        const cursoNailText = cursoNailTextRef.current;
        const cursoBrowText = cursoBrowTextRef.current;

        if (cursoNailText) {
            cursoNailText.classList.toggle('visible', isVisible.nail);
            cursoNailText.classList.toggle('hidden', !isVisible.nail);
        }

        if (cursoBrowText) {
            cursoBrowText.classList.toggle('visible', isVisible.brow);
            cursoBrowText.classList.toggle('hidden', !isVisible.brow);
        }
    }, [isVisible]);

    return (
        <div className="services" id="services">
            <h2>Cursos Profissionalizantes</h2>
            <div className="card1" ref={cursoBtnNailRef} onClick={handleCursoNailClick}>
                <h1>Nail Designer</h1>
                <h3>Vagas Abertas!</h3>
            </div>
            <div className="card2" ref={cursoBtnBrowRef} onClick={handleCursoBrowClick}>
                <h1>Brow Designer</h1>
                <h3>Em breve</h3>
            </div>
            <div id="text-curso" ref={cursoNailTextRef} className={isVisible.nail ? "visible" : "hidden"}>
                <div id='txt1'>
                    <h1>Conteúdo Online e Aulas Presenciais:</h1>
                    <h3>Instrutor presencial para dar o APOIO necessário aos alunos.</h3>
                    <h1>Módulo Duplo:</h1>
                    <h3>Apostilas contendo módulo teórico e prático.</h3>
                    <h1>Modalidade: </h1>
                    <h3>Semi-presencial</h3>
                    <h1>Carga Horária:</h1>
                    <h3>60 Horas</h3>
                    <h1>Instrutor(a):</h1>
                    <h3>Marli Mendes</h3>
                    <h1>Acesso Imediato:</h1>
                    <h3>Assim que a sua inscrição for confirmada <br /> você vai receber o acesso IMEDIATAMENTE em seu email.</h3><br />
                </div>
                <div id='txt2'>
                    <p>Em uma abordagem dinâmica semi-presencial, nossa academia oferece uma experiência única, combinando aprendizado <i><b>prático presencial</b></i> e <i><b>teórico online</b></i> para prepará-lo para o mercado de trabalho.</p> <br />
                    <p>Sob a orientação experiente de nossa dedicada professora, você será imerso em um <b>conteúdo abrangente, detalhado e atualizado.</b> Com mais de <b>20 anos</b> de experiência no ramo e um histórico de sucesso na formação de centenas de profissionais, nossa <b>professora presencial</b> é o alicerce perfeito para a sua jornada rumo à <b>excelência</b> na profissão de <b>manicure</b>.</p><br />
                    <p>Desde técnicas básicas até os mais avançados truques do ofício, nosso curso é projetado para capacitá-lo com as habilidades essenciais necessárias para se destacar no mercado. Você aprenderá não apenas a embelezar as <b>unhas</b>, mas também a cuidar da <b>saúde</b> e bem-estar de seus clientes, garantindo serviços de <b>qualidade</b> e segurança.</p><br />
                    <p>Não importa se você está dando os primeiros passos em sua carreira ou buscando aprimoramento profissional, o nosso curso oferece o suporte e conhecimento necessário para você alcançar seus objetivos. Prepare-se para uma jornada emocionante no mundo da <b>beleza</b> e torne-se o profissional de sucesso que você sempre quis ser com o nosso curso.</p><br />
                    <h2>
                        MODALIDADES DISPONIVEIS
                    </h2>
                </div>
            </div>
            <div id="text-curso2" ref={cursoBrowTextRef} className={isVisible.brow ? "visible" : "hidden"}>
                <div id='txt1'>
                    <h1>Conteúdo Online e Aulas Presenciais:</h1>
                    <h3>Professora presencial para dar o APOIO necessário aos alunos.</h3>
                    <h1>Módulo Duplo:</h1>
                    <h3>Apostilas contendo módulo teórico e prático.</h3>
                    <h1>Modalidade: </h1>
                    <h3>Semi-presencial</h3>
                    <h1>Carga Horária:</h1>
                    <h3>16 Horas</h3>
                    <h1>Instrutor(a):</h1>
                    <h3>EM BREVE</h3>
                    <h1>Acesso Imediato:</h1>
                    <h3>Assim que a sua inscrição for confirmada <br /> você vai receber o acesso IMEDIATAMENTE em seu email.</h3><br />
                </div>
                <div id='txt2'>
                    <p>Em uma abordagem dinâmica semi-presencial, nossa academia oferece uma experiência única, combinando aprendizado <i><b>prático presencial</b></i> e <i><b>teórico online</b></i> para prepará-lo para o mercado de trabalho.</p> <br />
                    <p>Sob a orientação experiente de nossa dedicada professora, você será imerso em um <b>conteúdo abrangente, detalhado e atualizado.</b> Com mais de <b>10 anos</b> de experiência no ramo e um histórico de sucesso na formação de centenas de profissionais, nossa <b>professora presencial</b> é o alicerce perfeito para a sua jornada rumo à <b>excelência</b> na profissão de <b>designer de sobrancelhas</b>.</p><br />
                    <p>Desde técnicas básicas até os mais avançados truques do ofício, nosso curso é projetado para capacitá-lo com as habilidades essenciais necessárias para se destacar no mercado. Você aprenderá não apenas a embelezar as <b>sobrancelhas</b>, mas também a cuidar da <b>saúde</b> e bem-estar de seus clientes, garantindo serviços de <b>qualidade</b> e segurança.</p><br />
                    <p>Não importa se você está dando os primeiros passos em sua carreira ou buscando aprimoramento profissional, o nosso curso oferece o suporte e conhecimento necessário para você alcançar seus objetivos. Prepare-se para uma jornada emocionante no mundo da <b>beleza</b> e torne-se o profissional de sucesso que você sempre quis ser com o nosso curso.</p><br /><br />
                    <a onclick="return false;" href="//" class="hotmart-fb hotmart__button-checkout"><button id="Inscrever3">EM BREVE</button></a>
                </div>
            </div>
            <div className='item2'>
                <Benefits />
                <Benefits2 />
            </div>
            <div className="item1">
                    <ContactForm />
            </div>
        </div>
    );
};

export default Services;
