import React from "react";
import '../styles/Contato.css';
import Fone from './icons/fone.png';
import Eicon from './icons/email.png';
import Face from './icons/facebook.svg';
import Insta from './icons/instagram.svg';
import Wapp from './icons/wapp.svg';



const Contato = () => (

    <div className="Contato">

        <div className="fone-mail"> 
            <a href="https://api.whatsapp.com/send?phone=5519978167330"><img src={Fone} alt="Telefone" id="telefone"/>
            <h3>(19) 97816 7330</h3></a>
            <a href="mailto:contato@academiagirassol.com.br?subject=SUPORTE"><img src={Eicon} alt="Enviar e-mail" id="email"/>
            <h3>contato@academiagirassol.com.br</h3></a><hr/>
            <h3>CNPJ - 28.743.580/0001-26</h3><hr/>
        </div>
        <div className="social-icons">
            <a href="https://www.facebook.com/academygirassol" target="_blank" rel="noreferrer"><img src={Face} alt="Facebook" id="Facebook"/></a>
            <a href="https://www.instagram.com/academiagirassol/" target="_blank" rel="noreferrer"><img src={Insta} alt="Instagram" id="Instagram"/></a>
            <a href="https://api.whatsapp.com/send?phone=5519978167330" target="_blank" rel="noreferrer"><img src={Wapp} alt="Whatsapp" id="Whatsapp"/></a>
            <h3>Siga-nos nas redes sociais!</h3>
        </div>
        
    </div>  

);

export default Contato;