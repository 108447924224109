import React from "react";
import NailDesigner from "./icons/fotodestaquemanicure.jpg";
import Afiacao from './icons/afiacaobg.jpg';
import SobreBG from './icons/FOTO2.jpg';
import VagaSocial from './icons/vagasocial.png';
import CapaBrow from './icons/CAPABROW.png';
import '../styles/HeroSlider.css';
import Flickity from 'react-flickity-component';

const flickityOptions = {
    wrapAround: true,
    autoPlay: 6000, // Mudar slide a cada 6 segundos
    pauseAutoPlayOnHover: false, // Não pausar no hover
    prevNextButtons: true,  // Habilitar botões de navegação
    contain: true, 
    initialIndex: 1
};

const HeroSlider = () => {
    const slides = [
        { image: NailDesigner, header: "Manicure e Pedicure", text: "Design em Unhas", link: "https://academiagirassol.hotmart.host/design-em-unhas", buttonText: "Saiba mais!" },
        { image: CapaBrow, header: "Design em Sobrancelhas", text: "Brow Designer", link: "https://academiagirassol.hotmart.host/design-em-sobrancelhas", buttonText: "Saiba mais!" },
        { image: Afiacao, header: "Serviço Especializado", text: "Afiação de Alicates", link: "https://abrir.link/BOhfx", buttonText: "Fale conosco!" },
        { image: SobreBG, header: "Sobre Nossa", text: "Escola", link: "#sobre", buttonText: "Saiba mais!" },
        { image: VagaSocial, text: "Design em Unhas", link: "https://api.whatsapp.com/send?phone=5519978167330&text=Olá!%20Eu%20gostaria%20de%20me%20inscrever%20para%20as%20vagas%20sociais%20do%20curso%20de%20manicure!.", buttonText: "Saiba mais!" },
    ];

    return (
        <Flickity className={'hero-slider'} elementType={'div'} options={flickityOptions} reloadOnUpdate>
            {slides.map((slide, index) => (
                <div
                    key={index}
                    className="carousel-cell"
                    style={{ backgroundImage: `url(${slide.image})`, backgroundColor: '#f4f4f4', backgroundSize: 'cover', backgroundPosition: 'center' }}
                >
                    <div className="info-container">
                        {slide.header && <h3 className="header">{slide.header}</h3>}
                        <h2 className="text">{slide.text}</h2>
                        {slide.link.startsWith('#') ? (
                            <a href={slide.link}>
                                <button>{slide.buttonText}</button>
                            </a>
                        ) : (
                            <a href={slide.link} target="_blank" rel="noreferrer">
                                <button>{slide.buttonText}</button>
                            </a>
                        )}
                    </div>
                </div>
            ))}
        </Flickity>
    );
};

export default HeroSlider;
