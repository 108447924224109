import React, { useEffect } from 'react';
import LogoBg from './icons/logo.png';
import '../styles/Main.css';

const Main = () => {
  useEffect(() => {
    const handleScroll = () => {
      const video = document.querySelector('.videobg');
      const scrollPosition = window.scrollY;
      const parallaxOffset = scrollPosition * 0; // Ajuste conforme necessário
      video.style.transform = `translateY(${parallaxOffset}px)`;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className='parallax'>
      <div className="video-container">
        <video className="videobg" autoPlay loop muted playsInline>
          <source src="https://videos.pexels.com/video-files/3971844/3971844-hd_1920_1080_25fps.mp4" type="video/mp4" />
        </video>
      </div>
      <div className='content'>
        <main>
          <img src={LogoBg} alt='Logo Academia de Beleza Girassol' id='logobg' />
          <p>Ensinando pessoas e transformando vidas.</p>
        </main>
      </div>
    </div>
  );
};

export default Main;
