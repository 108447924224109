import React from "react";
import '../styles/Benefits.css';
import Available from "./icons/available.svg";
import HotmartComponent from './HotmartComponent';

const Benefits2 = () =>  (
     <div id="beneficio">

        <h2>Curso Semi-presencial</h2>
        
        <div className="benefit-item">
            <img src={Available} alt="Incluso" id="available"/>
            <h3>Acesso Vitalicio a 2 Apostilas Digitais.</h3>
        </div>
        <div className="benefit-item">
            <img src={Available} alt="Incluso" id="available"/>
            <h3>10 Video-aulas teóricas e práticas.</h3>
        </div>
        <div className="benefit-item">
            <img src={Available} alt="Incluso" id="available"/>
            <h3>Certificado de conclusão garantido.</h3>
        </div> 
        <div className="benefit-item">
            <img src={Available} alt="Incluso" id="available"/>
            <h3>Acesso à comunidade de networking.</h3>
        </div>
        <div className="benefit-item">
        <img src={Available} alt="Incluso" id="available"/>
            <h3>Aulas Presenciais Teóricas e Práticas nos Studios Profissionais da Academia Girassol.</h3>
        </div>
        <div className="benefit-item">
        <img src={Available} alt="Incluso" id="available"/>
            <h3> 60h de Aulas Práticas garantido no certificado.</h3>
        </div>
        <div className="benefit-item">
        <img src={Available} alt="Incluso" id="available"/>
            <h3>Suporte exclusivo de Instrutor Profissional.</h3>
        </div>
        <div className="price-btn">
            <p>Por apenas</p>
        </div>
        <div className="price">
            <h3>Em até 12x <span style={{fontSize: '2em'}}>R$99,70</span>/Mês</h3>
            <HotmartComponent />
            <br/>
            <a href="https://academiagirassol.hotmart.host/design-em-unhas" target="__blank" id="saibamais">Saiba Mais+</a>
        </div>
     </div>
);

export default Benefits2;