import React from 'react';
import '../styles/Topicon.css';
            
const Topicon = () => (
    <body>    
        <a href="#Inicio" id="topo"> </a>
    </body>


);

export default Topicon;
            