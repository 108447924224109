import React, { useEffect } from 'react';

const HotmartComponent = () => {
  useEffect(() => {
    function importHotmart() {
      try {
        // Verifica se o script já foi adicionado
        if (!document.querySelector('script[src="https://static.hotmart.com/checkout/widget.min.js"]')) {
          // Cria o elemento <script> e define a fonte do script
          var importedScript = document.createElement('script');
          importedScript.src = 'https://static.hotmart.com/checkout/widget.min.js';
          
          // Adiciona um listener para lidar com erros de script
          importedScript.onerror = function () {
            console.error('Erro ao carregar o script da Hotmart.');
          };

          document.head.appendChild(importedScript);
        }

        // Verifica se o link já foi adicionado (se necessário)
        if (!document.querySelector('link[href="https://pay.hotmart.com/W92532436G?off=1dxtukrk&checkoutMode=10"]')) {
          var link = document.createElement('link');
          // link.href = 'URL_DO_SEU_LINK_AQUI'; // Especifique a URL se necessário
          document.head.appendChild(link);
        }
      } catch (error) {
        console.error('Erro ao importar o script da Hotmart:', error);
      }
    }

    importHotmart();

    // Opcional: Remover o script quando o componente for desmontado
    return () => {
      const existingScript = document.querySelector('script[src="https://static.hotmart.com/checkout/widget.min.js"]');
      if (existingScript) {
        existingScript.remove();
      }
    };
  }, []); // O array vazio [] garante que o useEffect rode apenas uma vez, após a montagem

  return (

      <a onclick="return false;" href="https://pay.hotmart.com/W92532436G?off=1dxtukrk&checkoutMode=10" class="hotmart-fb hotmart__button-checkout"><button id="Inscrever4">Comprar Agora</button></a>

  );
};

export default HotmartComponent;