import React, { useState, useEffect, useRef } from 'react';
import logo from './icons/icon.png';
import Wapp from './icons/wapp.svg';
import Insta from './icons/instagram.svg';
import Face from './icons/facebook.svg';
import '../styles/Header.css';



const Header = () => {

    const [isVisible, setIsVisible] = useState(false);

    const handleContatoClick = () => {
        setIsVisible(!isVisible);
      };
    
    const contatoBtnRef = useRef(null);
    const socialIconsRef = useRef(null);
  
    useEffect(() => {
      const contatoBtn = contatoBtnRef.current;
      const socialIcons = socialIconsRef.current;
  
      const handleContatoClick = () => {
        socialIcons.classList.toggle('visible');
        socialIcons.classList.toggle('hidden');
      };
  
      if (contatoBtn) {
        contatoBtn.addEventListener('click', handleContatoClick);
      }
  
      return () => {
        if (contatoBtn) {
          contatoBtn.removeEventListener('click', handleContatoClick);
        }
      };
    }, []);
  
    return (


        <header id="Inicio">
            <div class="Container">
                <div id="logo">
                    <a href="/"><img src={logo} alt="Logotipo" href="index.html"/></a>
                </div>
                <nav>
                    <div class="desktop-menu">
                    <label id='headerlabel' for="check">&#x268c;</label>
                    <input type="checkbox" name="menu" id="check"/>
                        <ul>
                            <div class="menu-item"><li><a href="#services">Cursos Profissionalizantes</a></li></div>
                            <div class="menu-item"><li><a href="#sobre">Sobre a escola</a></li></div>
                            <div class="menu-item"><li><button id="btn-social" ref={contatoBtnRef} onClick={handleContatoClick}>Fale Conosco</button></li></div> 
                            <div id="social-icons" ref={socialIconsRef} className="hidden">
                                <a href="https://api.whatsapp.com/send?phone=5519978167330" className="whatsapp-icon" target="_blank" rel="noopener noreferrer" title="whatsapp">
                                <img src={Wapp} alt="WhatsApp" id="whatsapp-icon"/>
                                </a>
                                <a href="https://www.instagram.com/academiagirassol/" className="instagram-icon" target="_blank" rel="noopener noreferrer" title="instagram">
                                    <img src={Insta} alt="Instagram" id="instagram-icon"/>
                                </a>
                                <a href="https://www.facebook.com/academygirassol/" className="facebook-icon" target="_blank" rel="noopener noreferrer" title="facebook">
                                    <img src={Face} alt="Facebook" id="facebook-icon"/>
                                </a>
                            </div>
                        </ul>
                    </div>
                </nav>
            </div>
            <div class="line"/>
        </header>

    );
};

export default Header;