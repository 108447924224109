import React from "react";
import '../styles/Footer.css';

const Footer = () => (

    <div className="Creditos">
        <a href='https://www.skyzdesign.com.br'><h3>© 2024 - Skyy Design</h3></a>
    </div>

);

export default Footer;